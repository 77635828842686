import { render, staticRenderFns } from "./fraction.vue?vue&type=template&id=09371bdc&scoped=true&"
import script from "./fraction.vue?vue&type=script&lang=js&"
export * from "./fraction.vue?vue&type=script&lang=js&"
import style0 from "./fraction.vue?vue&type=style&index=0&id=09371bdc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09371bdc",
  null
  
)

export default component.exports